
import { Dialog, ImagePreview } from 'vant'
import { defineComponent, reactive, ref, computed, onMounted, watch, nextTick } from 'vue'
import { useRouter } from 'vue-router'
// import { areaList } from '@vant/area-data'
import { areaList } from '@/consts/index'
import useOpenInfo from '@/hooks/openInfo'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import useMutationCommon from '@/hooks/useMutationCommon'
import stepPercentage from '@/utils/number'
import { getPictureAction, isInAppWebview, setApptitle } from '@/utils/native-app'
import { queryFn } from '@/graphql'
import beforeUpload from '@/utils/before-upload'
import { BusinessType, CameraOptions, ImageType, Source } from '@/enum'
import uploadApi from '@/axios/upload'
import { useToggle } from '@vant/use'
import { dataURLtoFile, unzip } from '@/utils/file'
import { nanoid } from 'nanoid'
import useQueryFileTemp from '@/hooks/useFileTemp'
import { Message } from '@/components/Message'

export default defineComponent({
  setup () {
    const router = useRouter()
    // vuex store数据
    const {
      openInfo,
      openType,
      meta,
      title,
      step,
      saveOpenInfoOfVuex,
      saveCommonOfVuex
    } = useOpenInfo()
    // 查询数据字典
    const dicRes = ref()
    // 就业状态列表
    const professionOptions = ref()
    // 教育程度列表
    const educationOptions = ref()
    // 业务性质列表
    const businessTypeOptions = ref()
    // 保存步骤信息
    const form: any = reactive({
      email: '',
      addressChecked: '',
      asFamilyAddress: '',
      contactAddress: '',
      contactAddressPre: '',
      contactDetailAddress: '',
      educationLevelText: '',
      educationLevelIdx: '',
      educationLevel: '',
      professionCodeText: '',
      professionCodeIdx: '',
      professionCode: 0,
      professionCodeOther: '',
      companyBusinessTypeText: '',
      companyBusinessTypeIdx: '',
      companyBusinessType: '',
      companyName: '',
      jobPosition: '',
      contactAddressOther: '' // 通讯地址其他
    })
    // 缓存图片列表
    const tempImagesList = useQueryFileTemp({
      kindSource: openType.value
    })
    // 监听缓存表图片变化
    watch(
      () => tempImagesList.value,
      () => {
        nextTick(() => {
          const png = tempImagesList.value?.filter(
            (val: { imageType: number; idKindType: number }) =>
              val.imageType === ImageType.ACC_IMAGE_TYPE_502
          )
          fileList.value = png.length ? [{ url: png[0].remoteUrl, status: 'done' }] : []
        })
      }
    )
    const fileList: any = ref([])
    // 住址证明显示
    const isProf = computed(() => {
      if (!form.addressChecked && openType.value === 1) {
        return true
      } else if (!form.asFamilyAddress && openType.value !== 1) {
        return true
      } else {
        return false
      }
    })
    // 相机选项
    const actionsList = [
      { key: CameraOptions.CAMERA, name: '拍摄' },
      { key: CameraOptions.PHOTO, name: '相册' }
    ]
    // 上传文件
    const [fileActionState, fileActionToggle] = useToggle()
    // 判断是否在金马app内
    const isApp = computed(() => isInAppWebview())

    const uploadToApp = () => {
      if (!isApp.value || fileList.value.length) {
        return
      }
      fileActionToggle(true)
    }
    // 删除图片
    const fileDel = () => {
      fileList.value = []
    }
    // 上传图片
    const uploadFile = (file: File) => {
      beforeUpload(file).then((res: any) => {
        const formatFile = new File([res], res.name, { type: res.type })
        fileList.value = [
          {
            url: '',
            status: 'uploading',
            message: '上传中...'
          }
        ]
        const params = {
          file: formatFile,
          imageType: ImageType.ACC_IMAGE_TYPE_502,
          businessType: BusinessType.ACC_FILE_BUSINESS_TYPE_1,
          kindSource: openType.value,
          idKindType: openInfo.value.idKind,
          source: Source.H5
        }
        uploadApi.uploadFile(params).then((res: any) => {
          const { openAccountUploadFile } = res
          if (openAccountUploadFile) {
            fileList.value = [
              {
                url: openAccountUploadFile.data,
                status: 'done'
              }
            ]
          } else {
            console.log('上传失败')
            Message({
              direction: 'vertical',
              text: openAccountUploadFile?.reason?.desc || '上传失败，请重试'
            })
          }
        })
      })
    }
    // 上传图片前钩子
    const beforeRead = (file: File) => {
      uploadFile(file)
    }
    const onFileSelect = async (item: { key: string }) => {
      const url = await getPictureAction(item.key)
      if (url) {
        const base64 = unzip(url)
        const imgUrl = `data:image/png;base64,${base64}`
        const png = dataURLtoFile(imgUrl, `${nanoid()}.png`)
        uploadFile(png)
      }
    }
    const viewExp = () => {
      ImagePreview({
        images: [require('@/assets/images/juzhuzhengming.png')],
        closeable: true
      })
    }
    const showAddressPicker = ref(false)
    const showProfessionPicker = ref(false)
    const showEducationPicker = ref(false)
    const showBTPicker = ref(false)
    const addressOptions = ['']
    const pattern = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
    const changeAddress = (val: boolean) => {
      if (val) {
        form.contactAddress = openInfo.value.idCardAddress
        form.contactAddressPre = ''
        form.contactDetailAddress = ''
        form.contactAddressOther = ''
      } else {
        form.contactAddress = form.contactAddressPre.replace(/\//g, '') + form.contactDetailAddress
      }
    }
    const changeToFamily = (val: boolean) => {
      if (val) {
        form.contactAddress = openInfo.value.familyAddress
        form.contactAddressPre = ''
        form.contactDetailAddress = ''
        form.contactAddressOther = ''
      } else {
        form.contactAddress = form.contactAddressPre.replace(/\//g, '') + form.contactDetailAddress
      }
    }
    // 用户信息回填
    const initData = async () => {
      Object.keys(form).forEach(val => {
        const res = openInfo.value[val] ? openInfo.value[val] : form[val]
        form[val] = res
      })
      if (form.asFamilyAddress && openType.value !== 1) {
        form.contactAddress = openInfo.value.familyAddress
      }
      if (form.addressChecked && openType.value === 1) {
        form.contactAddress = openInfo.value.idCardAddress
      }
      const res = await queryFn('findDictOpenAccount', {
        markList: ['EDUCATION_LEVEL', 'PROFESSION_CODE', 'COMPANY_BUSINESS_TYPE']
      })
      dicRes.value = res.data
      professionOptions.value = dicRes.value.PROFESSION_CODE.map(
        (val: { name: string; value: number }) => ({
          text: val.name,
          value: Number(val.value)
        })
      )
      educationOptions.value = dicRes.value.EDUCATION_LEVEL.map(
        (val: { name: string; value: number }) => ({
          text: val.name,
          value: Number(val.value)
        })
      )
      businessTypeOptions.value = dicRes.value.COMPANY_BUSINESS_TYPE.map(
        (val: { name: string; value: string }) => ({
          text: val.name,
          value: Number(val.value)
        })
      )
    }
    // 通讯地址
    const onConfirm = (values: [{ name: string }]) => {
      form.contactAddressPre = values
        .filter(item => !!item)
        .map(item => item.name)
        .join('/')
      showAddressPicker.value = false
    }
    const onProfessionConfirm = (val: { text: string; value: number }, idx: number) => {
      form.professionCode = val.value
      form.professionCodeText = val.text
      form.professionCodeIdx = String(idx)
      showProfessionPicker.value = false
    }
    const onEducationConfirm = (val: { text: string; value: number }, idx: number) => {
      form.educationLevel = val.value
      form.educationLevelText = val.text
      form.educationLevelIdx = String(idx)
      showEducationPicker.value = false
    }
    const onBusinessTypeConfirm = (val: { text: string; value: string }, idx: number) => {
      form.companyBusinessTypeText = val.text
      form.companyBusinessType = String(val.value)
      form.companyBusinessTypeIdx = String(idx)
      showBTPicker.value = false
    }
    // todo 需要重新考虑
    const isDisabled = computed(() => {
      let result = form.email && form.educationLevel && form.professionCode !== ''
      if (openType.value === 1) {
        if (form.addressChecked) {
          result = result && form.contactAddress
        } else {
          if (form.contactAddressPre === '其他') {
            result = result && form.contactAddressOther && form.contactDetailAddress
          } else {
            result = result && form.contactAddressPre && form.contactDetailAddress
          }
        }
      }
      if (openType.value !== 1) {
        if (form.asFamilyAddress) {
          result = result && form.contactAddress
        } else {
          if (form.contactAddressPre === '其他') {
            result = result && form.contactAddressOther
          } else {
            result = result && form.contactAddressPre && form.contactDetailAddress
          }
        }
      }
      if (![3, 4, 5, 6].includes(form.professionCode)) {
        result = result && form.companyName && form.jobPosition && form.companyBusinessTypeText
      }

      if (form.professionCode === 7) {
        result = result && form.professionCodeOther
      }
      if (isProf.value) {
        result = result && fileList.value.length && fileList.value[0].status === 'done'
      }

      return !result
    })

    // 保存下一步调用接口
    const saveNext = () => {
      if (form.contactAddressPre === '其他') {
        form.contactAddress = form.contactAddressOther + form.contactDetailAddress
      }
      const percentage = stepPercentage(step)
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify(form)
      }
      const common = {
        openType: openType.value,
        openStatus: 0,
        btnText: '继续开户',
        remark: `已完成${percentage}%，请继续完善资料`,
        step
      }
      useSaveOpenInfo(params).then(() => {
        useMutationCommon(common)
        saveCommonOfVuex({ info: common })
        saveOpenInfoOfVuex({ info: form })
        router.push({ name: 'financial-info' })
      })
    }

    const handleNext = () => {
      if (
        (!form.asFamilyAddress && openType.value !== 1) ||
        (!form.addressChecked && openType.value === 1)
      ) {
        form.contactAddress = form.contactAddressPre.replace(/\//g, '') + form.contactDetailAddress
      }
      console.log(form, 'form')
      Dialog.confirm({
        title: '邮箱地址确认',
        message: `邮箱将用于接收交易结单、找回密码操作。请再次确认：${form.email}`,
        cancelButtonText: '取消',
        confirmButtonText: '确认无误'
      })
        .then(() => {
          saveNext()
        })
        .catch(() => {
          //
        })
    }

    onMounted(() => {
      setApptitle(title)
      initData()
    })
    // 处理其他输入框
    const filterAddress = () => {
      const values = Object.values(areaList.province_list)
      values.forEach(item => {
        if (form.contactAddressOther === item) {
          form.contactAddressOther = ''
        }
      })
    }
    return {
      handleNext,
      isDisabled,
      form,
      addressOptions,
      showAddressPicker,
      showProfessionPicker,
      showEducationPicker,
      showBTPicker,
      educationOptions,
      professionOptions,
      businessTypeOptions,
      onConfirm,
      onEducationConfirm,
      onProfessionConfirm,
      onBusinessTypeConfirm,
      pattern,
      changeAddress,
      changeToFamily,
      initData,
      areaList,
      openType,
      isProf,
      fileList,
      beforeRead,
      uploadToApp,
      isApp,
      fileDel,
      fileActionToggle,
      actionsList,
      fileActionState,
      onFileSelect,
      viewExp,
      filterAddress
    }
  }
})
